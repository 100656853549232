.page-404 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  padding-left: 140px;

  @media (--mediaTablet) {
    & {
      padding-top: 80px;
      padding-bottom: 80px;
      padding-left: 80px;
      grid-template-columns: 100%;
      min-height: 100vh;
      height: auto;
    }
  }

  @media (--mediaMobile) {
    & {
      padding-left: 0;
    }
  }
}

@keyframes page-404-video {
  0% {
    opacity: 0;
    transform: scale3d(0, 1, 1);
    transform-origin: left;
  }
  49% {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }
  50% {
    transform: scale3d(1, 1, 1);
    transform-origin: right;
  }
  100% {
    transform: scale3d(0, 1, 1);
    transform-origin: right;
  }
}

.page-404__video-container {
  width: 100%; 
  height: 100%;
  overflow: hidden;
  position: relative;
  border: 30px solid transparent;

  &::after {
    content: '';
    background: rgb(var(--rgbAccent));
    animation-duration: 1.8s;
    animation-timing-function: var(--bezierFastoutSlowin);
    position: absolute;
    inset: 0;
    transform: scale3d(0, 1, 1);
    transform-origin: left;
    z-index: 16;
  }

  @media (--mediaMobile) {
    & {
      min-height: 240px;
      grid-row: 1;
    }
  }
}

@media (--mediaUseMotion) {
  .page-404__video-container--entered::after {
    animation-name: page-404-video;
  }
}

.page-404__video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0;
  transition-property: opacity;
  transition-delay: 1s;
  transition-duration: var(--durationM);

  @media (--mediaMobile) {
    & {
      left: 0;
    }
  }
}

.page-404__video--entered {
  opacity: 1;
}

.page-404__credit {
  color: rgb(var(--rgbWhite) / 0.4);
  background: rgb(var(--rgbBlack) / 0.6);
  padding: var(--spaceXXS) var(--spaceXS);
  font-size: var(--fontSizeBodyS);
  position: absolute;
  bottom: var(--spaceS);
  left: var(--spaceS);
  transform: none;
  text-decoration: none;
  transition: opacity var(--durationM) ease var(--durationM), color var(--durationM) ease;
  opacity: 0;

  &:hover,
  &:focus {
    color: rgb(var(--rgbWhite));
  }
}

.page-404__credit--entered {
  opacity: 1;
}

.page-404__details {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spaceXL);
  height: 100%;

  @media (--mediaMobile) {
    & {
      grid-row: 2;
    }
  }
}

.page-404__text {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
}

.page-404__title {
  margin-bottom: var(--spaceM);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: 0.1s;
  opacity: 0;

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.page-404__title--entered {
  transform: none;
  opacity: 1;
}

.page-404__subheading {
  padding-bottom: var(--spaceL);
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: rgb(var(--rgbText) / 0.4);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: 0.2s;
  opacity: 0;
  max-width: 100%;
  white-space: nowrap;
  flex: 0 0 auto;

  @media (--mediaMobile) {
    & {
      font-size: calc((18 / 16) * 1rem);
    }
  }

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.page-404__subheading--entered {
  transform: none;
  opacity: 1;
}

.page-404__description {
  padding-bottom: var(--spaceL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: 0.3s;
  opacity: 0;

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.page-404__description--entered {
  transform: none;
  opacity: 1;
}

.page-404__button {
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationM);
  opacity: 0;
  align-self: flex-start;
  padding-left: var(--spaceXXS);

  @media (--mediaUseMotion) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.page-404__button--entered {
  transform: none;
  opacity: 1;
}
